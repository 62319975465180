import { Box, Portal, Tab, Tabs, Typography, useTheme } from '@mui/material';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import usePlayerStore from '../store/playerStore';
import { useEffect, useRef, useState } from 'react';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';
import Countdown from './Countdown/Countdown';
import AccessCodeForm from './AccessCodeForm/AccessCodeForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function PlayerContentRegistration() {
  // global store
  const { playerUi, registrationData, playerUser } = usePlayerStore();

  const {
    showRegistrationText,
    showEventText,
    showAgenda,
    showCountDown,
    confirmEmail,
  } = registrationData!;

  // state
  const [activeTab, setActiveTab] = useState(0);
  // const [regFormContainerRef, setRegFormContainerRef] = useState(null);

  const noConfirmcontainer = useRef(null);
  const confirmContainer = useRef(null);

  const regFormContainerRef = confirmEmail
    ? confirmContainer
    : noConfirmcontainer;

  // functions
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // theme
  const theme = useTheme();

  // get Agenda
  // const Agenda = useAgenda({ stage: 'marketing' });
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'marketing' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {showEventText && rewriteHtml(playerUi!.descriptionHtml)}

      {showRegistrationText && rewriteHtml(registrationData!.registrationText)}

      {showCountDown && <Countdown targetDate={playerUi!.eventStartDateTime} />}

      {/* FORMS */}
      <Box
        sx={{ display: 'flex', justifyContent: 'center', p: 2, pt: 4, pb: 4 }}
      >
        <Box sx={{ width: '100%', maxWidth: '480px' }}>
          {confirmEmail ? (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Registrieren"
                    sx={{
                      fontSize: theme.typography.body1.fontSize + ' !important',
                      width: '50%',
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Zugangscode eingeben"
                    sx={{
                      fontSize: theme.typography.body1.fontSize + ' !important',
                      width: '50%',
                    }}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={activeTab} index={0}>
                <Box ref={confirmContainer} />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                <AccessCodeForm />
              </CustomTabPanel>
            </>
          ) : (
            <Box ref={noConfirmcontainer} />
          )}

          <Portal container={() => regFormContainerRef.current!}>
            <RegistrationForm />
          </Portal>
        </Box>
      </Box>

      {showAgenda && (
        <Box sx={{ mb: 0 }}>
          {playerUi!.multiSession ? renderProgram() : renderAgenda()}
        </Box>
      )}
    </Box>
  );
}
